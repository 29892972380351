import Vue from 'vue'
import Vuex from 'vuex'
import router, {resetRouter} from "@/router";
import request from "@/utils/request";

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    state: {
        currentPathName: '',
    },
    mutations: {
        setPath (state) {
            state.currentPathName = localStorage.getItem("currentPathName")
        },
        logout() {
            localStorage.clear()
            router.push("/login")
            // 重置路由
            resetRouter()
        }
    }
})
