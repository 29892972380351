<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header style="padding: 0; border-bottom: #808f97 2px solid;width: 100%;">
        <Header />
      </el-header>
      <el-container>
        <el-aside width="200px">
          <Asider />
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>

    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Asider from "@/components/Asider.vue";
export default {
  name: 'Manager',
  components: {Asider, Header},
  data(){
    return{
      token: ''
    }
  }
}
</script>
