<template>
  <el-menu class="el-aside"
           :default-active="$route.path"
           style="min-height: 100%; overflow: hidden;text-align: left;"
           background-color="#05244f"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :unique-opened="true"
           router>
    <div style="height: 80px;line-height: 80px;text-align: center;">
<!--      <img src="../assets/invoice-white.png" alt="" style="width: 50px; position: relative; top: 17px; margin-right: 5px">-->
      <b style="color: white">数电发票助手</b>
    </div>
    <div v-for="item in menus" :key="item.id">
      <div v-if="item.path">
        <el-menu-item :index="item.path">
          <i :class="item.icon"></i>
          <span slot="title">{{item.name}}</span>
        </el-menu-item>
      </div>
      <div v-else>
        <el-submenu :index="item.id+''">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </template>
          <div v-for="subItem in item.children" :key="subItem.id">
            <div v-if="subItem.name.search('详情') === -1">
              <el-menu-item :index="subItem.path">
                <i :class="subItem.icon"></i>
                <span slot="title">{{subItem.name}}</span>
              </el-menu-item>
            </div>
          </div>
        </el-submenu>
      </div>
    </div>
  </el-menu>
</template>

<script>
export default {
  name: "Asider",
  data() {
    return {
      menus: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")) : [],
      opens: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")).map(v => v.id + '') : [],
    }
  }
}
</script>

<style scoped>

</style>
