import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Manager.vue'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/Login/login.vue')
  },{
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");

  if(storeMenus && storeMenus !== "null") {
    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Manager')) {
      const manageRoute = { path:"/", name:"Manager", component: () => import('../views/Manager.vue'), redirect: "/home", children:[
          { path:"/order/orderList/detail", name:"订单详情", component: () => import('../views/Order/orderInfoDetail.vue')},
          { path:"/order/orderList/SDInvoiceDetail", name:"数电发票-详情-发票详情", component: () => import('../views/Order/orderInfoSDDetail.vue')},
          { path:"/order/orderList/orderImportDetail", name:"导入开票-订单详情", component: () => import('../views/Order/orderImportDetail.vue')},
          { path:"/invoice/invoiceList/detail", name:"开票详情", component: () => import('../views/Invoice/invoiceInfoDetail.vue')}
        ]}
      const menus = JSON.parse(storeMenus)
      menus.forEach(item => {
        if(item.path) {  //当且仅当path不为空的时候才去设置路由
          let itemMenu = {path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + ".vue")}
          manageRoute.children.push(itemMenu)
        } else if (item.children.length) {
          item.children.forEach(item => {
            if (item.path) {
              let itemMenu = {path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + ".vue")}
              manageRoute.children.push(itemMenu)
            }
          })
        }
      })
      //动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }
  }
}

// 重置
setRoutes()

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name)
  localStorage.setItem("currentPath", to.path)
  store.commit("setPath")

  // 未找到路由的情况
  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus")
    if (storeMenus && storeMenus !== "null") {
      next("/404")
    } else {
      // 跳回登录页面
      next("/login")
    }
  }

  // 其他的情况都放行
  next()
})

export default router
