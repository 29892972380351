import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import request from "@/utils/request";
import store from './store'
import { configProfile, configMap } from '/public/config.js'


Vue.use(Element)

Vue.config.productionTip = false

Vue.prototype.request = request

Vue.prototype.dicAll = function (typeName) {
  if (!typeName) return ''
  let dict = JSON.parse(localStorage.getItem('dict'))
  return dict[typeName] || []
}

Vue.prototype.config = {//全局获取配置
  profile: configProfile,
  map: configMap
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('dateFilter', function (date) {
  if (date) {
    return date.replace('T', ' ')
  } else {
    return ''
  }
})
