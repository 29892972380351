<template>
  <div style="text-align: left; font-size: 12px; line-height: 60px;display: flex">
    <div style="flex: 1; font-size: 18px">
      <el-breadcrumb separator="/" style="display: inline-block; margin-left: 10px">
        <el-breadcrumb-item :to="'/'">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{currentPathName}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <template style="font-size: 18px">
      <template style="font-size: 18px">
        <el-popover
            placement="bottom-start"
            width="600"
            trigger="click"
            @show="getDiscList"
            style="padding-right: 20px">
          <el-table :data="discList" :loading="getDiskListLoading">
            <el-table-column width="150" property="diskId" label="终端编号"></el-table-column>
            <el-table-column property="accountName" label="数字账户"></el-table-column>
            <el-table-column width="120" property="accountStatus" label="账户连接状态">
              <template v-slot="scope" >
                <span style="color: #d20b79" v-if="scope.row.accountStatus === '0'">{{getDictTypeValue("accountStatus",scope.row.accountStatus)}}</span>
                <span style="color: #ff2c2c" v-if="scope.row.accountStatus === '1'">{{getDictTypeValue("accountStatus",scope.row.accountStatus)}}</span>
                <span style="color: #2c80dc" v-if="scope.row.accountStatus === '2'">{{getDictTypeValue("accountStatus",scope.row.accountStatus)}}</span>
                <span style="color: #00a854" v-if="scope.row.accountStatus === '3'">{{getDictTypeValue("accountStatus",scope.row.accountStatus)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="scope">
                <el-button type="text" @click="detectDiskStatus(scope.row)">税局状态检测</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button slot="reference">终端状态</el-button>
        </el-popover>
        <el-button type="text" style="padding-right: 10px">帮助</el-button>
        <span style="font-size: 13px;padding-right: 10px">当前时间:<span v-html="$options.filters.formatDate(date)" style="margin: 0 10px"></span></span>
      </template>
<!--      <span>选择店铺：</span>-->
<!--      <el-select filterable-->
<!--                 remote-->
<!--                 v-model="openId"-->
<!--                 @change="changeplatform"-->
<!--                 :remote-method="remoteMethod">-->
<!--        <el-option-->
<!--            v-for="item in platforms"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--            selected>-->
<!--        </el-option>-->
<!--      </el-select>-->
    </template>
    <div style="min-width: 200px; text-align: center;padding-right: 10px">
      <span style="font-size: 13px">当前店铺：</span>
      <el-dropdown style="cursor: pointer; text-align: center">
        <span>{{ shopName }}<i class="el-icon-arrow-down" style="margin-left: 5px"></i></span>
        <el-dropdown-menu style="width: 100px; text-align: center" slot="dropdown">
          <el-dropdown-item>
            <span style="text-decoration: none"  @click="logOut">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="终端状态" :visible.sync="detectDialogVisible" width="800px" :close-on-click-modal="false" v-loading="detectLoading">
      <el-form label-width="100px" :model="revenueForm">
        <el-form-item label="税号：">
          <span>{{revenueForm.xhfNsrsbh}}</span>
        </el-form-item>
        <el-form-item label="终端编号：">
          <span>{{revenueForm.diskId}}</span>
        </el-form-item>
        <el-form-item label="税局状态：">
          <span style="color: #d20b79" v-if="revenueForm.accountStatus === '0'">{{getDictTypeValue("accountStatus",revenueForm.accountStatus)}}</span>
          <span style="color: #ff2c2c" v-if="revenueForm.accountStatus === '1'">{{getDictTypeValue("accountStatus",revenueForm.accountStatus)}}</span>
          <span style="color: #2c80dc" v-if="revenueForm.accountStatus === '2'">{{getDictTypeValue("accountStatus",revenueForm.accountStatus)}}</span>
          <span style="color: #00a854" v-if="revenueForm.accountStatus === '3'">{{getDictTypeValue("accountStatus",revenueForm.accountStatus)}}</span>
        </el-form-item>
        <el-form-item label="数字账户：">
          <span>{{revenueForm.accountName}}</span>
        </el-form-item>
        <el-form-item label="短信验证码:" v-if="revenueForm.dlfs === '12' && (revenueForm.accountStatus === '0' || revenueForm.accountStatus === '1')">
          <el-input v-model.trim="sjhYzm" style="width: 40%;margin-right: 10px"></el-input>
          <el-button @click="getYzm()" :loading="getYzmLoading">获取验证码</el-button>
          <el-button @click="postYzm()" :loading="postYzmLoading" :disabled="sjhYzm.length < 6">上传验证码</el-button>
        </el-form-item>
        <el-form-item label="人脸认证:" v-if="revenueForm.accountStatus === '2'">
          <div v-loading="ewmBtn" style="display: inline-block;vertical-align: middle;">
            <div id="qrcode" ref="qrcode"></div>
            <div style="padding: 10px;font-size: 20px;color: #d93838">过期时间：{{expireTime}}</div>
            <div style="padding: 10px;font-size: 20px;color: #d93838">请使用企业所在地的税务App进行扫码</div>
            <span slot="footer" class="dialog-footer">
<!--              <el-button @click="">认证完毕</el-button>-->
              <el-button @click="refreshEWM">刷新</el-button>
            </span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detectDialogVisible = false; revenueForm = {}; sjhYzm = ''; expireTime = ''; qrcode = ''">关闭</el-button>
        <el-button @click="detectDiskStatus(revenueForm)">刷新</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import router, {resetRouter} from "@/router";
import th from "element-ui/src/locale/lang/th";
import request from "@/utils/request";
import QRCode from "qrcodejs2";
export default {
  name: "Header",
  data() {
    return {
      date:new Date(),
      token: "",
      discList: [],
      getDiskListLoading: false,
      detectDialogVisible: false,
      detectLoading: false,
      revenueForm: {},
      getYzmLoading: false,
      sjhYzm: '',
      postYzmLoading: false,
      ewmBtn: false,
      expireTime: "",
      qrcode: "",
      loginParam: {}
    };
  },
  computed:{
    shopName(){
      return localStorage.getItem("shopName")
    },
    currentPathName() {
      return this.$store.state.currentPathName;　　//需要监听的数据
    }
  },
  created() {
    this.token = localStorage.getItem("token")
    this.getDiscList("login")
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000)
  },
  methods: {
    logOut() {
      this.$store.commit("logout");
      this.$message.success("退出成功")
    },
    getDiscList(flag) {
      this.getDiskListLoading = true
      request.get("/revenueInfo/getBannerList/", {
        params: {
          ptdm: localStorage.getItem("ptdm"),
          shopId: localStorage.getItem("shopId")
        }
      }).then(res => {
        this.getDiskListLoading = false
        if (res.code === 200){
          this.discList = res.data
          if (flag === "login"){
            // 检查是否有状态异常的的终端
            for (let i = 0; i < this.discList.length; i++) {
              var errorList = ""
              if (this.discList[i].accountStatus === '0'){
                errorList += "终端编号：" + this.discList[i].diskId + "，数字账户：" + this.discList[i].accountName + "，税局状态：未获取到状态；\n"
              } else if (this.discList[i].accountStatus === '1'){
                errorList += "终端编号：" + this.discList[i].diskId + "，数字账户：" + this.discList[i].accountName + "，税局状态：需要重新登录；\n"
              } else if (this.discList[i].accountStatus === '2'){
                errorList += "终端编号：" + this.discList[i].diskId + "，数字账户：" + this.discList[i].accountName + "，税局状态：需要刷脸；\n"
              }
            }
            if (errorList !== '') {
              this.$alert(errorList, '数字账户状态提示', {
                customClass: 'msgbox',   // 自定义一个class名
                confirmButtonText: '确定',
                callback: action => {}
              });
            }
          }
        } else if (res.code === 500) {
          this.$message.info(res.msg)
        }
      }).catch(err => {
        this.getDiskListLoading = false
      })
    },
    getDictTypeValue(type, value) {
      for (let i = 0; i < this.dicAll(type).length; i++) {
        if (value === '' || value === null){
          return ''
        }
        if (this.dicAll(type)[i].value === value) {
          return this.dicAll(type)[i].label
        }
      }
    },
    detectDiskStatus(data){
      this.revenueForm.xhfNsrsbh = data.xhfNsrsbh
      this.revenueForm.diskId = data.diskId
      this.revenueForm.companySign = data.companySign
      this.revenueForm.accountName = data.accountName
      this.revenueForm.accountStatus = data.accountStatus
      this.revenueForm.dlfs = data.dlfs
      this.detectDialogVisible = true
      this.detectLoading = true
      this.loginParam.id = data.id
      this.loginParam.ptdm = localStorage.getItem("ptdm")
      this.loginParam.shopId = localStorage.getItem("shopId")
      this.loginParam.xhfNsrsbh = localStorage.getItem("xhfNsrsbh")
      this.loginParam.xhfMc = localStorage.getItem("xhfMc")
      this.loginParam.diskId = data.diskId
      this.loginParam.manner = data.dlfs
      this.loginParam.account = data.accountName
      this.loginParam.password = data.accountPass
      this.loginParam.bsrPhone = data.bsrPhone
      this.loginParam.loginIdentity = data.loginIdentity
      console.log(this.loginParam)
      request.get("/taxDigitalAccounts/getFaceStatus",{
        params: {
          diskId: data.diskId
        }
      }).then(res => {
        this.revenueForm.accountStatus = res.data.faceStatus
        this.detectLoading = false
        if (res.code === 200){
          this.$message.success(res.msg)
        } else if (res.code === 500) {
          this.$message.error(res.msg)
        }
        if (res.data.faceStatus === '2'){
          this.refreshEWM()
        }
      }).catch(err => {
        this.detectLoading = false
      })
    },
    getYzm() {
      this.getYzmLoading = true
      request.post("/taxDigitalAccounts/login", this.loginParam).then(res => {
        this.getYzmLoading = false
        if (res.code === 200){
          this.$message.success(res.msg)
          this.revenueForm.bsrPhone = res.data
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.getYzmLoading = false
        this.$message.error("获取验证码失败，请重试！")
      })
    },
    postYzm() {
      this.postYzmLoading = true
      this.loginParam.yzm = this.sjhYzm
      request.post("/taxDigitalAccounts/postCodeVerify",this.loginParam).then(res => {
        this.postYzmLoading = false
        if (res.code === 200){
          this.$message.success(res.msg)
          this.detectDiskStatus(this.revenueForm)
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.postYzmLoading = false
        this.$message.error("上传验证码失败，请重试！")
      })
    },
    refreshEWM() {
      this.ewmBtn = true
      request.get("/taxDigitalAccounts/faceEwm", {
        params:{
          diskId: this.revenueForm.diskId
        }
      }).then(res => {
        this.ewmBtn = false
        if (res.code === 200){
          this.expireTime = res.data.expireTime
          document.getElementById('qrcode').innerHTML = "";
          this.qrcode = new QRCode(document.getElementById("qrcode"), {
            width: 200, // 二维码宽度
            height: 200, // 二维码高度
            text: res.data.ewm
          });
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.ewmBtn = false
        this.$message.error("获取失败，请稍后重试！")
      })
    }
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = date.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = date.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      return y + '<span style="color: #05244f;margin:0 10px">年</span>' + MM + '<span style="color: #05244f;margin:0 10px">月</span>' + d + '<span style="color: #05244f;margin:0 10px">日</span> ' + h + ':' + m + ':' + s;
    }
  }
}
</script>

<style scoped>
</style>
<style>
.msgbox {
  width: 700px !important;
}
</style>
