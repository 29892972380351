//prod
// export const serverIp = '114.67.236.158'
// export const serverIp = '116.196.106.96'

//test
// export const serverIp = '116.198.198.7'
// export const serverIp = '116.196.106.96'

//local
// export const serverIp = 'localhost'

export const configProfile = 'prod' // local、yd_test、test、prod

export const ptdm = {
    jingdong: 'jingdong',
    douyin: 'douyin',
    xhs: 'xhs',
    kuaishou: 'kuaishou'
}

export const configMap = {
    local: {
        loginUrl: 'http://localhost:8270/login',
        serverIp: 'localhost:30000',
        redictUrlJD: "https://open-oauth.jd.com/oauth2/to_login?app_key=267C3E67ED3FF460342F026E148C986C&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8270%2Flogin&state=&scope=snsapi_base",
        redictUrlDY: "",
        redictUrlXHS: "https://ark.xiaohongshu.com/ark/authorization?appId=7b7cf6055eb84f539053&redirectUri=http%3A%2F%2F101.126.9.40%2Flogin&state=xhs",
        redictUrlKS: "https://open.kwaixiaodian.com/oauth/authorize?app_id=ks700028268618349358&redirect_uri=https%3A%2F%2Fwww.9tfp.com.cn%2Flogin&scope=merchant_refund,merchant_item,merchant_order,user_info,merchant_servicemarket,merchant_user,merchant_comment,merchant_logistics&response_type=code&state=kuaishou"
    },
    test: {
        loginUrl: 'http://101.126.23.160:8270/login',
        serverIp: '101.126.23.160/api',
        redictUrlJD: "https://open-oauth.jd.com/oauth2/to_login?app_key=267C3E67ED3FF460342F026E148C986C&response_type=code&redirect_uri=http%3A%2F%2F116.196.106.96%3A8270%2Flogin&state=&scope=snsapi_base",
        redictUrlDY: "",
        redictUrlXHS: "",
        redictUrlKS: ""
    },
    prod: {
        loginUrl: 'https://www.9tfp.com.cn/login',
        serverIp: 'www.9tfp.com.cn/api',
        redictUrlJD: "",
        redictUrlDY: "",
        //redictUrlXHS: "https://ark.xiaohongshu.com/ark/authorization?appId=7b7cf6055eb84f539053&redirectUri=http%3A%2F%2F101.126.9.40%2Flogin&state=xhs",
        redictUrlXHS: "https://ark.xiaohongshu.com/ark/authorization?appId=7b7cf6055eb84f539053&redirectUri=https%3A%2F%2Fwww.9tfp.com.cn%2Flogin&state=xhs",
        redictUrlKS: "https://open.kwaixiaodian.com/oauth/authorize?app_id=ks700028268618349358&redirect_uri=https%3A%2F%2Fwww.9tfp.com.cn%2Flogin&scope=merchant_refund,merchant_item,merchant_order,user_info,merchant_servicemarket,merchant_user,merchant_comment,merchant_logistics&response_type=code&state=kuaishou"
    }
}

export const guideline = {
    homePage: {
        main: {
            content: "操作指引，即将上线！"
        }
    },
    order: {
        orderList: {
            content: "操作指引，即将上线！"
        },
        orderImport: {
            content: "操作指引，即将上线！"
        },
        orderProblemList: {
            content: "操作指引，即将上线！"
        },
        orderAfterSales: {
            content: "操作指引，即将上线！"
        }
    },
    invoice: {
        invoiceList: {
            content: "操作指引，即将上线！"
        },
        QDInvoiceList: {
            content: "操作指引，即将上线！"
        },
        SKInvoiceList: {
            content: "操作指引，即将上线！"
        },
        upLoadInvoice: {
            content: "操作指引，即将上线！"
        }
    },
    goods: {
        TaxCodeMaintenance: {
            content: "操作指引，即将上线！"
        },
        MatchRule: {
            content: "操作指引，即将上线！"
        },
        InvoiceProductOnSale: {
            content: "操作指引，即将上线！"
        }
    },
    settings: {
        terminal: {
            content: "操作指引，即将上线！"
        },
        invoiceRule: {
            content: "操作指引，即将上线！"
        },
        userInfo: {
            content: "操作指引，即将上线！"
        }
    }
}

//提示参数
export const CHECK_PARAM = "请检查参数！"
export const OPERATION_SUCCESS = "操作成功"
export const OPERATION_ERROR = "操作失败！"
export const GET_LIST_ERROR = "获取列表失败！"
export const LOGIN_RESPONSE = {
    NO_INFO:{
        code: 201
    },
}
export const timePickerOption = [{
    text: '最近一周',
    onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
    }
}, {
    text: '最近两周',
    onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
        picker.$emit('pick', [start, end]);
    }
},{
    text: '最近一个月',
    onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
    }
},{
    text: '最近三个月',
    onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
        picker.$emit('pick', [start, end]);
    }
}]
